<template>
  <ag-grid-vue style="width: 100%; height: 100%;"
               class="ag-theme-alpine"
               ref="grid"
               :cacheBlockSize="50"
               :columnDefs="resultColumns"
               :rowData="data"
               :gridOptions="gridOptions"
               :animateRows="true"
               :frameworkComponents="frameworkComponents"
               @grid-ready="onGridReady"
  >
  </ag-grid-vue>
</template>

<script>
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import ColumnTypes from './ColumnTypes'
import RuLocale from './locale/ru'
import XrefFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/XrefFilter'
import StringSetFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/StringSetFilter'
import ResultColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/ResultColumnsBuilder'

export default {
  name: 'grid',
  components: {
    AgGridVue
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    dataSourceService: {
      type: Object,
      default: () => { return {} }
    },
    pagination: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    checkBoxSelection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      gridOptions: {
        rowModelType: Object.keys(this.dataSourceService).length ? 'serverSide' : 'clientSide',
        pagination: this.pagination,
        paginationPageSize: this.pageSize,
        rowSelection: this.multiSelection ? 'multiple' : 'single',
        columnTypes: ColumnTypes,
        enableRangeSelection: true,
        enableCharts: true,
        statusBar: {
          statusPanels: [{ statusPanel: 'agAggregationComponent' }]
        },
        sideBar: {
          toolPanels: ['columns', 'filters']
        },
        localeText: RuLocale,
        defaultColDef: {
          sortable: true,
          enableValue: true,
          resizable: true,
          filterParams: {
            buttons: ['apply', 'reset']
          }
        }
      },
      gridApi: undefined,
      columnApi: undefined,
      frameworkComponents: {
        xrefFilter: XrefFilter,
        stringSetFilter: StringSetFilter
      }
    }
  },
  computed: {
    resultColumns () {
      let cols = ResultColumnsBuilder.build(this.columns)
      console.log(cols)
      return cols
    }
  },
  methods: {
    load () {
      this.gridApi.purgeServerSideCache()
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.$emit('grid-ready')
      this.afterRender()
    },
    afterRender () {
      if (this.dataSourceService) {
        this.gridApi.setServerSideDatasource(this.dataSourceService)
      }
    }
  }
}
</script>

<style scoped>

</style>
