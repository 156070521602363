import { Nullable } from '@/core/domain/type/types'
import { ValueFormatterParams } from 'ag-grid-community'

export default class ValueFormatters {
  static dateField (params: ValueFormatterParams): Nullable<string> {
    if (!params.value) {
      return null
    }
    return ValueFormatters.formatDate(params.value)
  }

  private static formatDate (date: string): string {
    const dateFormatter = new Intl.DateTimeFormat()
    return dateFormatter.format(new Date(date))
  }

  static xrefField (params: ValueFormatterParams): Nullable<string> {
    const value = typeof params.value === 'string' ? JSON.parse(params.value) : params.value

    if (value && Array.isArray(value)) {
      return value.map((item) => item.name).join(', ')
    }

    return null
  }

  static fileField (params: ValueFormatterParams): Nullable<string> {
    const files = JSON.parse(params.value || '[]')
    if (files instanceof Array && files.length > 0) {
      const result = files.map(file => {
        return `<a href='/files/${file.guid}.${file.extension}' download><i class="el-icon-document"> ${file.name}.${file.extension}</i></a>`
      })
      return result.join('<br>')
    }

    return null
  }

  static dateTimeField (params: ValueFormatterParams): Nullable<string> {
    if (!params.value) {
      return null
    }
    const date = params.value.split(' ')[0]
    const time = params.value.split(' ')[1]

    return `${ValueFormatters.formatDate(date)} ${time}`
  }

  static floatField (params): Nullable<number> {
    return typeof params.data[params.colDef.field] === 'string' ? parseFloat(params.data[params.colDef.field]) : null
  }
}
