export default class ResultColumnsBuilder {
  static requiredFields = [
    'headerName',
    'field',
    'type',
    'enableRowGroup',
    'filter',
    'filterParams'
  ]
  static build (columns: { children, isExtended, isStringSetFilter }[]): object[] {
    let result = []

    columns.forEach((column) => {
      let object: { children, filter } = {
        children: undefined,
        filter: undefined
      }
      for (let property in column) {
        if (!column.hasOwnProperty(property)) {
          continue
        }
        if (this.requiredFields.includes(property)) {
          object[property] = column[property]
        }
      }

      if (column.children) {
        object.children = ResultColumnsBuilder.build(column.children)
      }

      if (column.isExtended) {
        object = Object.assign(object, {
          autoHeight: true,
          wrapText: true
        })
      }
      if (column.isStringSetFilter) {
        object.filter = 'stringSetFilter'
      }

      result.push(object)
    })

    return result
  }
}
