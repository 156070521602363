import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { ColumnVO } from 'ag-grid-community/dist/lib/interfaces/iColumnVO'
import { Nullable } from '@/core/domain/type/types'

export class RequestBuilder {
  static build (request: IServerSideGetRowsRequest, pivotFieldIsXref: boolean = false, initialFilters: Nullable<object[]> = null): object {
    let filters = this.paginationBuild(request.startRow, request.endRow)

    filters = Object.assign(filters, this.wherePartBuild(
      request.filterModel,
      request.rowGroupCols,
      request.groupKeys,
      initialFilters
    ))
    filters = Object.assign(filters, this.orderPartBuild(request.sortModel))
    filters = Object.assign(filters, this.groupPartBuild(request.rowGroupCols, request.groupKeys, request.valueCols))

    if (request.pivotMode) {
      filters = Object.assign(filters, this.pivotPartBuild(request.pivotCols, request.valueCols, pivotFieldIsXref))
    }

    return filters
  }

  private static paginationBuild (startRow: number, endRow: number): object {
    return {
      limit: (endRow - startRow),
      offset: startRow
    }
  }

  private static pivotPartBuild (pivotCols: ColumnVO[], valueCols: ColumnVO[], pivotFieldIsXref: boolean = false): object {
    if (pivotCols.length === 0) {
      return {}
    }
    let pivotValues = {}
    valueCols.forEach((column: ColumnVO) => {
      pivotValues[column.id] = column.aggFunc
    })
    return {
      pivot_is_xref: pivotFieldIsXref,
      pivot: pivotCols[0].id,
      pivot_values: pivotValues
    }
  }

  private static groupPartBuild (groupCols: ColumnVO[], groupKeys: string[], valueCols: ColumnVO[]): object {
    if (groupCols.length > groupKeys.length) {
      let result = {
        group: groupCols[groupKeys.length].id,
        fields: [groupCols[groupKeys.length].id]
      }
      valueCols.forEach((column:ColumnVO) => {
        result[column.id] = { func: column.aggFunc, as: column.id }
      })
      return result
    }

    return {}
  }
  private static orderPartBuild (sortModel: []): object {
    if (sortModel.length === 0) return {}

    return {
      order: sortModel.map(function (item: {colId, sort}) {
        return `${item.colId}:${item.sort}`
      }).join(',')
    }
  }

  private static wherePartBuild (filter: object, groupCols:ColumnVO[], groupKeys: string[], initialFilters: Nullable<object[]> = null) {
    let whereParts = []
    Object.keys(filter).forEach((key) => {
      const item = filter[key]
      let mapper = null
      switch (item.filterType) {
        case 'text':
          mapper = this.textFilterMapper
          break
        case 'number':
          mapper = this.textFilterMapper
          break
        case 'date':
          mapper = this.textFilterMapper
          break
        case 'xref':
          mapper = this.textFilterMapper
          break
        case 'string_set':
          mapper = this.stringSetFilterMapper
          break
        default:
          console.log('unknown filter type: ' + item.filterType)
          break
      }
      if (mapper) {
        const condition = this.createCondition(mapper, key, item)
        if (condition && Object.keys(condition).length > 0) {
          whereParts.push(condition)
        }
      }
    })
    if (groupKeys.length > 0) {
      groupKeys.forEach((key, index) => {
        let filter = {
          eq: {}
        }
        filter.eq[groupCols[index].id] = key
        whereParts.push(filter)
      })
    }
    if (initialFilters && initialFilters.length > 0) {
      whereParts = [...whereParts, ...initialFilters]
    }
    if (whereParts.length === 0) {
      return {}
    }

    return {
      where: {
        and: whereParts
      }
    }
  }

  private static createCondition (
    mapper: (field: string, props: { type, filter?, filterTo?, dateFrom?, dateTo? }) => object,
    field: string,
    props: { type, filter, filterTo, operator, condition1, condition2 }
  ) {
    if (props.operator) {
      const condition1 = mapper(field, props.condition1)
      const condition2 = mapper(field, props.condition2)

      let result = {}
      result[props.operator.toLowerCase()] = [condition1, condition2]

      return result
    }

    return mapper(field, props)
  }

  private static textFilterMapper (field: string, props: { type, filter, filterTo }): object {
    let filter = {}
    switch (props.type) {
      case 'equals':
        filter['eq'] = {}
        filter['eq'][field] = props.filter
        break
      case 'notEqual':
        filter['neq'] = {}
        filter['neq'][field] = props.filter
        break
      case 'contains':
        filter['like'] = {}
        filter['like'][field] = `%${props.filter}%`
        break
      case 'notContains':
        filter['not_like'] = {}
        filter['not_like'][field] = `%${props.filter}%`
        break
      default:
        console.log('unknown text filter type: ' + props.type)
        break
    }

    return filter
  }

  private static numberFilterMapper (field: string, props: { type, filter, filterTo }): object {
    let filter = {}
    switch (props.type) {
      case 'equals':
        filter['eq'] = {}
        filter['eq'][field] = props.filter
        break
      case 'notEqual':
        filter['neq'] = {}
        filter['neq'][field] = props.filter
        break
      case 'greaterThan':
        filter['gt'] = {}
        filter['gt'][field] = props.filter
        break
      case 'greaterThanOrEqual':
        filter['gte'] = {}
        filter['gte'][field] = props.filter
        break
      case 'lessThan':
        filter['lt'] = {}
        filter['lt'][field] = props.filter
        break
      case 'lessThanOrEqual':
        filter['lte'] = {}
        filter['lte'][field] = props.filter
        break
      case 'inRange':
        filter['spec'] = 'between'
        filter[field] = `${props.filter}, ${props.filterTo}`
        break
      default:
        console.log('unknown number filter type: ' + props.type)
        break
    }

    return filter
  }
  private static dateFilterMapper (field: string, props: { type, dateFrom, dateTo }): object {
    let filter = {}
    const dateFrom = props.dateFrom.split(' ')[0]
    const dateTo = (props.dateTo || '').split(' ')[0]
    switch (props.type) {
      case 'equals':
        filter['eq'] = {}
        filter['eq'][field] = dateFrom
        break
      case 'notEqual':
        filter['neq'] = {}
        filter['neq'][field] = dateFrom
        break
      case 'greaterThan':
        filter['gt'] = {}
        filter['gt'][field] = dateFrom
        break
      case 'greaterThanOrEqual':
        filter['gte'] = {}
        filter['gte'][field] = dateFrom
        break
      case 'lessThan':
        filter['lt'] = {}
        filter['lt'][field] = dateFrom
        break
      case 'lessThanOrEqual':
        filter['lte'] = {}
        filter['lte'][field] = dateFrom
        break
      case 'inRange':
        filter['spec'] = 'between'
        filter[field] = `${dateFrom}, ${dateTo}`
        break
      default:
        console.log('unknown date filter type: ' + props.type)
        break
    }

    return filter
  }

  private static xrefFilterMapper (field:string, props: {type, value}):object {
    let filter = {}
    if (props.type === 'active') {
      if (props.value.length > 0) {
        filter['equals_any'] = {}
        filter['equals_any'][`${field}id`] = props.value.join(',')
      } else {
        filter['eq'] = {}
        filter['eq'][field] = '[]'
      }
    }

    return filter
  }

  private static stringSetFilterMapper (field:string, props: {type, value}):object {
    let filter = {}
    if (props.type === 'active') {
      if (props.value.length > 0) {
        filter['or'] = props.value.map((item) => {
          let object = {}
          object['eq'] = {}
          object['eq'][field] = item

          return object
        })
      } else {
        filter['is_null'] = field
      }
    }

    return filter
  }
}
