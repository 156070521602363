import Vue from 'vue'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { XrefAPI } from '@/services/RegistryTable/infrastructure/api/XrefAPI'
import { ExtendedObject } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/ExtendedObject'
import { Query } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/Query'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'

export default Vue.extend({
  template: `<div class="ag-filter-wrapper ag-focus-managed">
  <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">

    <div class="ag-set-filter">
      <div v-show="loading" class="ag-filter-loading">Загрузка...</div>
      <!--AG-INPUT-TEXT-FIELD-->
      <div class="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input v-model="search" class="ag-input-field-input ag-text-field-input" type="text" placeholder="Поиск...">
        </div>
      </div>
      <div v-if="!loading && options.length === 0" class="ag-filter-no-matches ag-hidden">Не найдено</div>
      <div v-else-if="!loading" class="ag-set-filter-list" role="presentation">
        <div class="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed" role="listbox">
          <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
          <div class="ag-virtual-list-container ag-filter-virtual-list-container" ref="eContainer">
            <div style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                   @click="selectAllOptions"
              >
                <div class="ag-input-field-label ag-label ag-checkbox-label">
                  (Выбрать все)
                </div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{
                      'ag-checked': isAllOptionsChecked,
                      'ag-indeterminate': !isAllOptionsChecked && options.filter((item) => item.checked).length > 0
                    }"
                >
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
                </div>
              </div>
            </div>
            <div v-for="(item, index) in options.filter((item) => search.length ? (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : true)" :key="index" style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div @click="item.checked = !item.checked"
                   class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div ref="eLabel" class="ag-input-field-label ag-label ag-checkbox-label">
                  {{ item.name  }}
                </div>
                <div ref="eWrapper"
                     class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{'ag-checked': item.checked}"
                     role="presentation">
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input"
                         type="checkbox">
                </div>
              </div>
            </div>
          </div>
          <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="ag-filter-apply-panel">
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="apply">ОК</button>
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="reset">Сбросить</button>
  </div>
  </div>`,
  data () {
    return {
      options: [],
      valueGetter: null,
      loading: false,
      error: null,
      search: '',
      type: null,
      entityId: null
    }
  },
  mounted () {
    const dataSourceService = this.$parent.$parent.dataSourceService
    if (dataSourceService instanceof ExtendedObject ||
      dataSourceService instanceof Query) {
      this.type = (dataSourceService instanceof ExtendedObject) ? 'ExtendedObject' : 'Query'
      if (this.type === 'ExtendedObject') {
        this.entityId = dataSourceService.extendedObjectId
      } else {
        this.entityId = dataSourceService.queryId
      }
    }
    if (this.type && this.entityId) {
      this.loadOptions(this.type, this.entityId)
    }
  },
  computed: {
    isAllOptionsChecked () {
      return this.options.filter((item) => item.checked).length === this.options.length
    }
  },
  methods: {
    reset () {
      this.options.forEach((item) => { item.checked = true })
      this.params.filterChangedCallback()
    },
    apply () {
      this.params.filterChangedCallback()
    },
    selectAllOptions () {
      let flagToSet = false
      if (this.options.some((item) => !item.checked)) {
        flagToSet = true
      }
      this.options.forEach((item) => { item.checked = flagToSet })
    },
    async loadOptions (type, entityId) {
      this.loading = true
      let options = []
      if (type === 'ExtendedObject') {
        options = await APIClient.shared.request(new APIDataSource.ExtendedObjectQuery(
          entityId,
          {
            'fields': [this.params.colDef.field],
            'group': this.params.colDef.field
          }
        ))
      } else {
        options = await APIClient.shared.request(new APIDataSource.QueryQuery(
          entityId,
          {
            'fields': [this.params.colDef.field],
            'group': this.params.colDef.field
          }
        ))
      }

      this.options = options.map((item) => {
        let object = {}
        object.name = item[this.params.colDef.field]
        object.checked = true
        return object
      })
      this.loading = false
    },
    isFilterActive () {
      return !this.isAllOptionsChecked
    },

    getModel () {
      return { filterType: 'string_set', type: this.isFilterActive() ? 'active' : 'non-active', value: this.options.filter((item) => item.checked).map((item) => item.name) }
    },

    setModel (model) {
      console.warn('setModel method in StringSetFilter is disabled', model)
      /* if (model && Array.isArray(model)) {
        this.options.forEach((item) => {
          if (model.includes(item.id)) {
            item.checked = true
          }
        })
      } */
    }
  }
})
