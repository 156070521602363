import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { ColumnApi } from 'ag-grid-community/dist/lib/columnController/columnApi'

export default class PivotColumnsBuilder {
  public static build (request: IServerSideGetRowsRequest, pivotFields: string[], columnApi: ColumnApi): void {
    const pivotColumns = this.createPivotColumns(request, pivotFields)
    columnApi.setSecondaryColumns(pivotColumns)
  }
  private static createPivotColumns (request: IServerSideGetRowsRequest, pivotFields: string[]) {
    function addPivotColumn (columnId: string, parts: string[], result: { groupId, children }[]): { groupId, children }[] {
      if (parts.length === 0) return []
      let first = parts.shift()
      let existing = result.filter(function (r) {
        return r.groupId === first
      })[0]
      if (existing) {
        existing['children'] = addPivotColumn(columnId, parts, existing.children)
      } else {
        let colDef: { groupId, headerName, children, field } = {
          groupId: undefined,
          headerName: undefined,
          children: undefined,
          field: undefined
        }
        let isGroup = parts.length > 0
        if (isGroup) {
          colDef['groupId'] = first
          colDef['headerName'] = first
        } else {
          let valueCol = request.valueCols.filter(function (r) {
            return r.field === first
          })[0]
          colDef['colId'] = columnId.replace('$$', '_')
          colDef['headerName'] = valueCol.displayName
          colDef['field'] = columnId.replace('$$', '_')
        }
        let children = addPivotColumn(columnId, parts, [])
        if (children.length > 0) {
          colDef['children'] = children
        }
        result.push(colDef)
      }
      return result
    }
    if (request.pivotMode && request.pivotCols.length > 0) {
      let secondaryCols = []
      let resultedPivotFields = []
      request.valueCols.forEach((item) => {
        resultedPivotFields = [...resultedPivotFields, ...pivotFields.map((pivotField) => `${pivotField}$$${item.id}`)]
      })

      resultedPivotFields.forEach(function (field) {
        addPivotColumn(field, field.split('$$'), secondaryCols)
      })
      return secondaryCols
    }
    return []
  }
}
