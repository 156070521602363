import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RequestBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/RequestBuilder'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'
import PivotColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/PivotColumnsBuilder'
import { Nullable } from '@/core/domain/type/types'

export class Query implements IServerSideDatasource {
  private readonly queryId: number;
  private externalFilters: Nullable<object[]>;
  constructor (queryId: number, externalFilters: Nullable<object[]> = null) {
    this.queryId = queryId
    this.externalFilters = externalFilters
  }

  setExternalFilters (externalFilters: Nullable<object[]>) {
    this.externalFilters = externalFilters
  }

  getRows (params: IServerSideGetRowsParams) {
    const pivotColumnIsXref = params.request.pivotCols.length > 0
      ? params.columnApi.getColumn(params.request.pivotCols[0].id).getUserProvidedColDef().type.includes('xrefField')
      : false
    const payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters)

    APIClient.shared.request(new APIDataSource.QueryQuery(this.queryId, payload))
      .then((response) => {
        let data
        let pivot_fields
        if (params.request.pivotMode && params.request.pivotCols.length > 0) {
          data = response.data
          pivot_fields = response.pivot_fields
          if (params.request.groupKeys.length === 0) {
            PivotColumnsBuilder.build(params.request, pivot_fields, params.columnApi)
          }
        } else {
          data = response
        }
        params.successCallback(data, data.length)
      })
      .catch((error) => {
        params.failCallback()
        throw error
      })
  }
}
